


import { useAppDispatch } from '@/src/hooks/useDispatch';
import { userLogout } from '@/store/slices/userSlice';
import { resetBookmark } from '@/store/slices/bookmarkSlice';
import { organizerLogout } from '@/store/slices/organizerSlice';
import { signOut } from "next-auth/react"
import { resetCart } from '@/store/slices/cartSlice';


export const useLogout = () => {
    const dispatch = useAppDispatch();

    const logout = async () => {
        dispatch(userLogout())
        dispatch(resetCart())
        dispatch(resetBookmark())
        dispatch(organizerLogout())
        // signOut()
        //clear local storage persist:root
        // localStorage.removeItem('persist:root')
    }
    return logout
}

