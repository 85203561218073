import {useState, useEffect} from 'react'
import axios from 'axios'
import { useRouter } from 'next/router';
import { fileToBase64 } from '@/src/helpers/image';
import { HiOutlineX } from "react-icons/hi";
import { SelectTag } from '@/src/atoms/tag/selectTag'



import { DrawerContainer } from '@/src/components-v3/containers/DrawerContainer';
import { NotificationCard, NotificationType } from "@/src/components-v3/dropdowns/notification-dropdown/NotificationCard"

//redux
import { useAppSelector } from '@/src/hooks/useSelector';
import { useAppDispatch } from '@/src/hooks/useDispatch';
import { updateNotificationLastChecked } from '@/store/slices/userSlice';

//instructor type
import { InstructorDataType } from '@/src/types-v3/instructor';


//types
import { OrganizerCalendarType } from '@/src/types-v3/organizer';
import { ClassAttendanceType } from '@/src/types-v3/booking';
import { BannerType } from '@/src/atoms-v3/Banner';

//helpers
import { displayDate7 } from '@/src/helpers/datetime_locale';
import Link from 'next/link';


interface NotificationDrawerProps {
    isVisible: boolean;
    hideModalCallback: () => void;
    notificationList: NotificationType[];
    readNotificationHandler: (id:number | string) => void;
    readAllNotificationsHandler: () => void;
    loadNextHandler?: () => void;
    l?: 'en' | 'jp'
}


export const NotificationDrawer = ({
    isVisible,
    hideModalCallback,
    notificationList,
    readNotificationHandler,
    readAllNotificationsHandler,
    loadNextHandler,
    l='jp',
}:NotificationDrawerProps) => {
    const router = useRouter();
    const dispatch = useAppDispatch();

    const userData = useAppSelector(state => state.user).data
    const {token, id} = userData


    /** LOADING */
    const [buttonLoading, setButtonLoading] = useState(false)
    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (buttonLoading) {
        // Set a timer to check loading state after 10 seconds
        timer = setTimeout(() => {
            setButtonLoading(false);
        }, 10000); // 10 seconds in milliseconds
        }
        // Clear the timer if loading becomes false before 10 seconds
        return () => clearTimeout(timer);
    }, [buttonLoading]);


    /**
     * NOTIFICATION DATA STATES
     */
    const [displayNotificationList, setDisplayNotificationList] = useState<NotificationType[]>([])
    
    
    /** display tags */
    type DisplayType = 'all' | 'unread'
    const [displayType, setDisplayType] = useState<DisplayType>('all')
    useEffect(() => {
        if (displayType=='all') {
            setDisplayNotificationList(notificationList)
        } else {
            const filteredList = notificationList.filter((n) => !n.is_read)
            setDisplayNotificationList(filteredList)
        }
    },[displayType, notificationList])


    /**
     * TEXT TRANSLATIONS
    */
    const text:any = {
        notifications:{
            en:'Notifications',
            jp:'お知らせ'
        },
        no_notifications: {
            en:'You have no new notifications',
            jp:'新しい知らせはありません'
        },
        all:{
            en:'All',
            jp:'すべて'
        },
        unread:{
            en:'Unread',
            jp:'新規'
        },
        load_more: {
            en: 'Load More',
            jp: 'もっと見る'
        }
    }
    

    return (
        <DrawerContainer
            is_left={false}
            isVisible={isVisible}
            hideDrawerCallback={hideModalCallback}
            parentClassName="h-full w-[90vw] sm:w-[500px]"
        >

            <div className='flex flex-col items-center justify-start'>
                <div className='flex flex-row gap-1 items-center justify-center relative w-full border-b p-3'>
                    <HiOutlineX 
                        size={24} onClick={() => hideModalCallback()}
                        className='absolute left-4 cursor-pointer '
                    />
                    <h3>
                        Notifications
                    </h3>
                </div>
                <div className='flex flex-row gap-2 items-center'>
                    <div 
                        onClick={() => setDisplayType('all')}
                        className='cursor-pointer'>
                        <SelectTag 
                            text={text['all'][l]}
                            selected={displayType=='all'}
                        />
                    </div>
                    <div 
                        onClick={() => setDisplayType('unread')}
                        className='cursor-pointer'>
                        <SelectTag 
                            text={text['unread'][l]}
                            selected={displayType=='unread'}
                        />
                    </div>
                    <div 
                        onClick={() => readAllNotificationsHandler()}
                        className='ml-auto cursor-pointer hover:underline'>
                        <p className='text-sm'>Mark all as Read</p>
                    </div>
                </div>

                <div className='flex flex-col gap-0'>
                    {   displayNotificationList.length ? (
                        displayNotificationList.map((n, index) => (
                            <NotificationCard 
                                notifObject={n}
                                key={index}
                                onClick={() => readNotificationHandler(n.id)}
                            />
                        )) 
                    ): (
                        <p>{text['no_notifications'][l]}</p>
                    )}
                    {   loadNextHandler ? (
                        <div 
                            className='w-full cursor-pointer hover:bg-gray-100 flex justify-center p-1'
                            onClick={loadNextHandler}
                        >
                            <p className='font-semibold text-blue-600'>
                                {text['load_more'][l]}
                            </p>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
                
            </div>
        </DrawerContainer>
    )
}