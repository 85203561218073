import {useState, useEffect, useRef, useMemo} from 'react'
import { useRouter } from 'next/router';
import axios from 'axios'
import { HiOutlineBell } from "react-icons/hi";


//redux
import { useAppSelector } from '@/src/hooks/useSelector';
import { useAppDispatch } from '@/src/hooks/useDispatch';
import { updateNotificationLastChecked } from '@/store/slices/userSlice';


import { NotificationDrawer } from './NotificationDrawer';
import { NotificationCard, NotificationType } from "@/src/components-v3/dropdowns/notification-dropdown/NotificationCard"

interface NotificationDropdownProps {

}


export const NotificationDropdown = ({

}:NotificationDropdownProps) => {
    const dispatch = useAppDispatch();
    const router = useRouter();
    //get user redux state
    const userData = useAppSelector(state => state.user).data
    const {token,id, notification_last_checked} = userData
    
    //language translation settings
    const l = useAppSelector(state => state.settings).data.language

    /**
     * ENV VAR STATES
     */
    const [backend_url, setBackendUrl] = useState<string | null>(null)
    const [ws_url, setWsUrl] = useState<string | null>(null)
    useEffect(() => {
        const loadEnvVars = async() => {
            const res = await fetch('/api/env/backend-url')
            const {backendUrl} = await res.json();
            if (backendUrl) { setBackendUrl(backendUrl) }

            const res2 = await fetch('/api/env/ws-url')
            const {wsUrl} = await res2.json();
            if (wsUrl) { setWsUrl(wsUrl) }
        }
        loadEnvVars()
    },[])


    //dropdown state
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const toggleDropdown = () => {
        // open dropdown
        setDropdownOpen(!isDropdownOpen);
    };




    /**
     * NOTIFICATION DATA STATE
     */
    const [notificationList, setNotificationList] = useState<NotificationType[]>([])
    //pagination url
    const [nextUrl, setNextUrl] = useState(null)
    //function for getting notifications from API
    const getNotifications = async(link: string) => {
        const config = {
            headers: {
                'Content-Type': 'Application/json',
                Authorization: `Bearer ${token}`
            }
        }
        try {
            const response = await axios.get( link, config )
            const {next, results} = response.data
            const processedResults = results.map((r:any) => {
                r.timestamp = new Date(r.timestamp)
                return r
            })
            setNotificationList(processedResults)
            setNextUrl(next)
            
        } catch (error) {
            console.log('error')
            console.log(error)
        } finally {

        }
    }
    // get initial notifications
    useEffect(() => {
        if (token && isDropdownOpen && backend_url) {
            const initiateNotifications = async () => {
                const link = `${backend_url}/api/notification/get-recent-user-notifications/`
                getNotifications(link)
                    
                // dispatch notification last checked update
                dispatch(updateNotificationLastChecked())
            }
            initiateNotifications()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[token, isDropdownOpen, backend_url])

    //load next notifications
    const loadNextHandler = async() => {
        if (nextUrl) {
            getNotifications(nextUrl)
        }
    }


    /**
     * SOCKET STATE
    */
    // create new socket & save to variable
    const [socket, setSocket] = useState<WebSocket | null>(null);
    useEffect(() => {
        if (id) {
            const initiateWebsocket = async () => {
                //set up websocket
                const url = `${ws_url}/ws/user-notification/${id}/`
                const newSocket = new WebSocket(url);
                setSocket(newSocket);
                console.log("websocket connected!!!")

                // received notification
                newSocket.onmessage = (event) => {
                    const data = JSON.parse(event.data)
                    console.log('Received notification:', data);
                    setNotificationList((prev) => [data, ...prev])
                }
                newSocket.onclose = () => {
                    console.log("websocket connection closed")
                }
                newSocket.onerror = (error) => {
                    console.error('WebSocket error:', error);
                }
                return () => {
                    newSocket.close();
                };
            }
            initiateWebsocket()
        }
    },[id])

    
    //read notification handler
    const readNotificationHandler = async(id:number | string) => {
        if (token) {
            const config = {
                headers: {
                    'Content-Type': 'Application/json',
                    Authorization: `Bearer ${token}`
                }
            }
            axios.patch(
                `${backend_url}/api/notification/read-user-notification/${id}/`,
                {},
                config
            )
                .then(response => {
                    const readNotifList = notificationList.map((n) => {
                        if (n.id==id) {
                            n.is_read = true
                        } 
                        return n
                    })
                    setNotificationList(readNotifList)
                    //console.log("this notif is read:", id)
                    //console.log(response)
                })
                .catch(error => {
                    console.log("error reading: ", id)
                    console.log(error)
                })
        }
    }
    //read notification handler
    const readAllNotificationsHandler = async() => {
        if (token) {
            const config = {
                headers: {
                    'Content-Type': 'Application/json',
                    Authorization: `Bearer ${token}`
                }
            }
            
            axios.patch(
                `${backend_url}/api/notification/read-all-user-notifications/`,
                {},
                config
            )
                .then(response => {
                    const readNotifList = notificationList.map((n) => {
                        n.is_read = true
                        return n
                    })
                    setNotificationList(readNotifList)
                    //console.log("this notif is read:", id)
                    //console.log(response)
                })
                .catch(error => {
                    console.log("error reading: ", id)
                    console.log(error)
                })
        }
    }


    /**
     * NEW ALERT STATE
     */
    const [newAlert, setNewAlert] = useState(false)
    // ref to reset scroll when visible
    const divRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (isDropdownOpen) {
            dispatch(updateNotificationLastChecked())
            setNewAlert(false)
            // if (divRef.current) {
            //     divRef.current.scrollTop = 0;
            // }
            //get notifications
            // getRecentUserNotifications()

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isDropdownOpen])



    return (
        <div className="relative inline-block text-left" ref={dropdownRef}>
            <button
                type="button"
                className={`inline-flex items-center space-x-2 text-gray-700 focus:outline-none 
                    p-1 rounded-full cursor-pointer
                    ${isDropdownOpen ? 'bg-gray-300 hover:bg-gray-400' : 'bg-white hover:bg-gray-100'}
                `}
                onClick={toggleDropdown}
            >
                <HiOutlineBell 
                    size={24} 
                    className=' cursor-pointer'
                />
                {/** NEW BADGE */}
                {   newAlert ? (
                    <div className="absolute inline-flex items-center justify-center w-3 h-3 text-xs font-bold text-white bg-red-600 rounded-full top-2 end-2">
                    </div>
                ) : (<></>)
                }
            </button>

            <NotificationDrawer 
                isVisible={isDropdownOpen}
                hideModalCallback={() => setDropdownOpen(false)}
                notificationList={notificationList}
                readNotificationHandler={readNotificationHandler}
                readAllNotificationsHandler={readAllNotificationsHandler}
                loadNextHandler={nextUrl ? loadNextHandler : undefined}
            />
            
        </div>
    )
}
