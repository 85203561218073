import React, {useRef, useEffect, useState, ReactNode} from 'react'

import { createPortal } from 'react-dom';

//hooks for modal
import useOutsideClick from '@/src/hooks/useOutsideClick2'


interface DrawerContainerProps {
    is_left?: boolean;

    containerClassName?: string;
    isStatic?: boolean;

    isVisible: boolean;
    hideDrawerCallback: () => void;
    children?: JSX.Element | ReactNode;
    parentClassName?: string;
}

/**
 * @param containerClassName : width, height, position from top
 * @param isStatic: static prevents modal from closing when clicking outside
 * @returns 
 */
export const DrawerContainer = ({
    is_left=true,
    
    containerClassName,
    isStatic=false,

    isVisible,
    hideDrawerCallback,
    children,
    parentClassName='',
}: DrawerContainerProps) => {

    //for outside click (!isStatic)
    const wrapperRef = useRef(null);
    const ref = useRef(null);
    useOutsideClick(ref, () => {hideDrawerCallback();})

    useEffect(() => {
        if (isVisible) {
            document.body.classList.add('overflow-hidden');
        } else {
            document.body.classList.remove('overflow-hidden');
        }
        return () => {
            // Clean up when the component unmounts
            document.body.classList.remove('overflow-hidden');
        };
    }, [isVisible]);



    // State to track drag movement
    const [dragStartX, setDragStartX] = useState<number | null>(null);
    const [dragDeltaX, setDragDeltaX] = useState<number>(0);


    // Touch drag handlers
    const handleTouchStart = (e: React.TouchEvent) => {
        if (isStatic) return; // Ignore for static modals
        setDragStartX(e.touches[0].clientX);
    };

    const handleTouchMove = (e: React.TouchEvent) => {
        if (dragStartX !== null) {
            setDragDeltaX(e.touches[0].clientX - dragStartX);
        }
    };

    const handleTouchEnd = () => {
        if (dragDeltaX < -100) {
            // If drag distance is significant (left-to-right), close the modal
            hideDrawerCallback();
        }
        // Reset drag tracking states
        setDragStartX(null);
        setDragDeltaX(0);
    };





    return (
        createPortal(
            <div  // Main Modal -- Overlay div
                tabIndex = {-1}         //for modal screen
                aria-hidden = {!isVisible}    //remove children from accessing tree
                ref = {wrapperRef}
                className = {`
                    fixed inset-0 z-[100] bg-black bg-opacity-50 w-full h-full
                    flex items-center justify-center 
                    overflow-x-hidden overflow-y-auto 
                    transition-opacity duration-300
                    ${isVisible ? 'opacity-100' : 'opacity-0 pointer-events-none'}
            `}> 
                {/**   Wrapper Div for the Modal Screen */}
                <div
                    ref = {isStatic ? null : ref}
                    className={`
                        transform transition-transform duration-300 ease-out
                        ${  is_left ? (
                            isVisible ? 'translate-x-0' : '-translate-x-full'
                        ) : (
                            isVisible ? 'translate-x-0' : 'translate-x-full'
                        )}
                        ${  is_left ? 'mr-auto' : 'ml-auto'}

                        relative h-[calc(100dvh)] 
                        bg-white
                        overflow-y-scroll
                        ${containerClassName}
                    `}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                >
                    {/**  Modal Content Div -- contains title & close button */}

                    <div className={`h-full ${parentClassName}`}>
                        {children}
                    </div>
                </div>
            </div>, document.body
        )
    )
}