
interface SelectTagProps {
  text: string;
  selected?: boolean;
}

export const SelectTag = ({
  text, selected=false
}:SelectTagProps) => {

  return (
    <div className={`
      w-fit py-2 px-4  rounded-full
      ${selected ? 'bg-blue-200' : 'bg-gray-200'}
    `}>
      <p 
        className={`text-sm  
          ${selected ? 'text-blue-800' : 'text-gray-800'}
          `}
        >{text}
      </p>
    </div>
  )
}


