import React, {useState, useEffect} from 'react'

import Image from 'next/image'

//redux
import { useAppSelector } from '@/src/hooks/useSelector';


export interface DisplayProfilePictureProps {
    height?: string;
    textSize?: string;
    image?: File | Blob |  null | string;
    alt?: string;
    className?: string;
    // dimensions?: {width:number, height:number} | null
    isRounded?: boolean;
    onClick?: () => void;
    placeholderLetter?: string;
    placeholderColor?: 'orange' | 'blue'

}

export const DisplayProfilePicture = ({
    height='h-[48px]',
    textSize='text-md',
    image=null,
    alt='Profile Picture',
    className,
    // dimensions=null,
    isRounded=true,
    onClick,
    placeholderLetter='A',
    placeholderColor='orange'
}:DisplayProfilePictureProps) => {
    const userData = useAppSelector(state => state.user).data
    const {is_organizer, username, email} = userData


    /**
     * PLACEHOLDER TEXT
     */
    // const placeholder = 'A'
    
    /**
     * IMAGE & IMAGE URL
    */
    const [imageUrl, setImageUrl] = useState('')
    useEffect(() => {
        if (image && typeof image!=='string') {
            setImageUrl(URL.createObjectURL(image))
        } else {
            setImageUrl(image ? image : '')
        }
    },[image])

    return (
        <div
            onClick={() => onClick && onClick()}
            className={`
                ${height} aspect-square
                 flex items-center justify-center
                ${isRounded ? 'rounded-full' : ''}
                ${onClick ? 'cursor-pointer' : ''}
                ${className}
            `}
        >
            <div 
                style={{width: '100%', height: '100%', position: 'relative'}}
                className={`
                    flex items-center justify-center
                    ${isRounded ? 'rounded-full' : ''}
                    ${placeholderColor=='blue' ? 'bg-blue-700' : 'bg-main-orange'}
                    overflow-hidden
            `}>
                {   (imageUrl) ? (
                    <Image
                        src={typeof image=='string' ? `${image}` : imageUrl} 
                        alt={alt}
                        style={{ objectFit: 'cover' }}
                        fill
                        sizes="(max-width: 768px) 100vw, 
                            (max-width: 1200px) 50vw, 
                            33vw"
                        onError={() => {
                            console.error('error reading image url, setting to none');
                            setImageUrl('');
                        }}
                    />
                ) : (
                    <div className={`flex flex-col relative items-center justify-center`}>
                        <p className={` ${textSize} text-white `}>
                            {placeholderLetter}
                        </p>
                            {/* <p 
                                className={`${textSize} text-white`}
                                style={{ fontFamily: 'G-Knewave' }}
                                >{placeholder}
                            </p> */}
                    </div>
                )}
            </div>
        </div>
    )
}