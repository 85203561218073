import {useState, useEffect} from 'react'
import { useRouter } from 'next/router';

import { useAppSelector } from '../../../hooks/useSelector';
import { displayDate5, displayTime1, isSameDate } from '../../../helpers/datetime_locale';


export type NotificationType = {
    id: string | number;
    photo?: string;
    message_en: string;
    message_jp: string;
    timestamp: Date;
    href?: string;
    is_read: boolean;
}

interface NotificationCardProps {
    notifObject: NotificationType
    onClick?: () => void;
}


export const NotificationCard = ({
    notifObject,
    onClick,
}:NotificationCardProps) => {
    const router = useRouter();

    //language translation settings
    const l = useAppSelector(state => state.settings).data.language

    const displayDate = (date:Date) => {
        try {
            if (isSameDate(new Date(date), new Date())) {
                return displayTime1(date)
            } else {
                return displayDate5(date)
            }
        } catch(error) {
            return ''
        }
    }

    const handleClick = () => {
        if (onClick) {onClick()}
        //set as read && go to page
        if (notifObject.href) {
            router.push(notifObject.href)
        }
    }

    return (
        <div 
            className={`w-full h-fit p-3 rounded-lg flex flex-row gap-4 cursor-pointer hover:bg-gray-100`}
            onClick={() => handleClick()}
        >
            <div className='h-[70px] w-[70px] aspect-square rounded-full bg-gray-200'>
            </div>
            <div className='w-full flex flex-col gap-2 justify-center'>
                <p className={`
                    line-clamp-2 text-sm font-medium
                    ${notifObject.is_read ? 'text-gray-400' : 'text-gray-900'}
                    `}>{    l=='jp' ?
                        notifObject.message_jp :
                        notifObject.message_en
                    }
                </p>
                <p className={` text-sm font-light
                    ${notifObject.is_read ? 'text-gray-400' : 'text-gray-900'}
                    `}>{displayDate(notifObject.timestamp)}
                </p>
            </div>
        </div>
    )
}